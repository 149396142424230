exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-age-restrictions-tsx": () => import("./../../../src/pages/age-restrictions.tsx" /* webpackChunkName: "component---src-pages-age-restrictions-tsx" */),
  "component---src-pages-bingo-blog-bingo-e-legalizado-no-brasil-tsx": () => import("./../../../src/pages/bingo-blog/bingo-e-legalizado-no-brasil.tsx" /* webpackChunkName: "component---src-pages-bingo-blog-bingo-e-legalizado-no-brasil-tsx" */),
  "component---src-pages-bingo-blog-como-ganhar-no-bingo-tsx": () => import("./../../../src/pages/bingo-blog/como-ganhar-no-bingo.tsx" /* webpackChunkName: "component---src-pages-bingo-blog-como-ganhar-no-bingo-tsx" */),
  "component---src-pages-bingo-blog-index-tsx": () => import("./../../../src/pages/bingo-blog/index.tsx" /* webpackChunkName: "component---src-pages-bingo-blog-index-tsx" */),
  "component---src-pages-bingo-bonus-tsx": () => import("./../../../src/pages/bingo-bonus.tsx" /* webpackChunkName: "component---src-pages-bingo-bonus-tsx" */),
  "component---src-pages-bingo-gratis-index-tsx": () => import("./../../../src/pages/bingo-gratis/index.tsx" /* webpackChunkName: "component---src-pages-bingo-gratis-index-tsx" */),
  "component---src-pages-bingo-gratis-nine-balls-tsx": () => import("./../../../src/pages/bingo-gratis/nine-balls.tsx" /* webpackChunkName: "component---src-pages-bingo-gratis-nine-balls-tsx" */),
  "component---src-pages-bingo-gratis-show-ball-3-tsx": () => import("./../../../src/pages/bingo-gratis/show-ball-3.tsx" /* webpackChunkName: "component---src-pages-bingo-gratis-show-ball-3-tsx" */),
  "component---src-pages-bingo-gratis-silverball-tsx": () => import("./../../../src/pages/bingo-gratis/silverball.tsx" /* webpackChunkName: "component---src-pages-bingo-gratis-silverball-tsx" */),
  "component---src-pages-cassino-online-brasil-deposito-minimo-5-reais-tsx": () => import("./../../../src/pages/cassino-online-brasil/deposito-minimo-5-reais.tsx" /* webpackChunkName: "component---src-pages-cassino-online-brasil-deposito-minimo-5-reais-tsx" */),
  "component---src-pages-cassino-online-brasil-index-tsx": () => import("./../../../src/pages/cassino-online-brasil/index.tsx" /* webpackChunkName: "component---src-pages-cassino-online-brasil-index-tsx" */),
  "component---src-pages-contatos-tsx": () => import("./../../../src/pages/contatos.tsx" /* webpackChunkName: "component---src-pages-contatos-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-copyright-notice-tsx": () => import("./../../../src/pages/copyright-notice.tsx" /* webpackChunkName: "component---src-pages-copyright-notice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

